<template>
  <div>
    <!-- <validation-observer #default="{ invalid }" ref="signupValidate"> -->
    <v-form ref="signupValidate" v-model="valid" lazy-validation>
      <!-- <validation-provider
          name="name"
          rules="required|min:3"
          #default="{ errors }"
        > -->
      <v-row class="mt-6">
        <v-col cols="12" md="12">
          <v-text-field
            dense
            color="primary"
            @keyup.enter.native="login"
            v-model="form.user_name"
            :rules="nameRules"
            label="Seu Nome"
          />
        </v-col>
      </v-row>
      <!-- </validation-provider>

        <validation-provider
          name="WhatsApp"
          rules="required|min:5"
          #default="{ errors }"
        > -->
      <v-row class="mt-1">
        <v-col cols="12" md="12">
          <app-whatsapp-field
            dense
            color="primary"
            @keyup.enter.native="login"
            v-model="form.phone_wa"
            label="WhatsApp"
            :rules="phoneRules"
          />
        </v-col>
      </v-row>
      <!-- </validation-provider>
        <validation-provider
          name="email"
          rules="required|email"
          #default="{ errors }"
        > -->
      <v-row class="mt-1">
        <v-col cols="12" md="12">
          <v-text-field
            dense
            color="primary"
            @keyup.enter.native="login"
            v-model="form.email"
            label="exemplo@exemplo.com"
            :rules="emailRules"
            type="email"
          />
        </v-col>
      </v-row>
      <!-- </validation-provider> -->

      <v-row class="mt-3">
        <v-col cols="6" md="6">
          <!-- <validation-provider
              name="senha"
              rules="required|min:8"
              #default="{ errors }"
            > -->
          <app-password-field
            color="primary"
            @keyup.enter.native="login"
            v-model="form.password"
            label="Senha"
            :rules="passwordRules"
            dense
          />
          <!-- </validation-provider> -->
        </v-col>
        <v-col cols="6" md="6">
          <!-- <validation-provider
              name="Confirmar senha"
              rules="required|min:8"
              #default="{ errors }"
            > -->
          <app-password-field
            color="primary"
            @keyup.enter.native="login"
            v-model="form.password_confirmation"
            label="Confirmar Senha"
            :rules="passwordRules"
            dense
          />
          <!-- </validation-provider> -->
        </v-col>
      </v-row>
    </v-form>
    <!-- </validation-observer> -->

    <v-row class="mt-10">
      <v-col>
        <v-btn @click="validationForm()" block color="primary">
          Experimente grátis!
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn text color="secondary" @click="$router.push('/login')">
          Já possui sua conta? clique aqui!
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "./../../../utils/validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail inválido",
      ],

      nameRules: [
        (v) => !!v || "Nome é obrigatório",
        (v) => (v && v.length >= 3) || "Nome deve ter no mínimo 3 caracteres",
      ],

      passwordRules: [
        (v) => !!v || "Senha é obrigatória",
        (v) => (v && v.length >= 8) || "Senha deve ter no mínimo 8 caracteres",
      ],

      phoneRules: [(v) => !!v || "WhatsApp é obrigatório"],

      valid: true,

      form: {
        user_name: null,
        company_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        signup_metadata: null,
      },
    };
  },

  mounted() {
    this.setSignupMetadata();
  },

  methods: {
    setSignupMetadata() {
      // console.log url
      console.log(window.location.href);

      console.log(this.$route.query);
      // this.form.signup_metadata = this.$route.query;
      try {
        this.form.signup_metadata = window.location.href;
      } catch (error) {
        console.log("error", error);
      }

      console.log(this.form.signup_metadata);
    },

    validationForm() {
      if (this.$refs.signupValidate.validate()) {
        this.signup();
      }
    },

    signup() {
      this.$loading.start();
      this.$http
        .store("app/signup", this.form)
        .then(async (response) => {
          this.$auth.storeToken(response.token);

          await this.$auth.getUserData();

          this.track({
            email: this.form.email,
            company_name: this.form.company_name,
            phone: this.form.phone_wa,
          });

          // uncomment to remove subscription alert on first login
          // this.$store.commit("app/setFistAccess", true);

          this.$router.push("/bem-vindo");

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    track(params) {
      this.$tracker.signUp(params);
    },

    googleSignup() {
      window.location.href = "http://app.pos.com.br/auth/google/signup";
    },
  },
};
</script>

<style>
</style>