<template>
  <v-container class="pa-1 text-center">
    <v-img aspect-ratio="7.9" src="@/assets/images/logo_roxo.png" />
    <div>
      <h2 class="mt-8">
        {{ company.name }} convidou você para experimentar a nossa plataforma !
        <br />
        <br />
        Crie sua conta e aproveite!
      </h2>
    </div>
    <SingUpForm />
  </v-container>
</template>
<script>
import SingUpForm from "@/components/auth/forms/SingUpForm.vue";

export default {
  components: { SingUpForm },

  mounted() {
    this.$tracker.track("VisitSignUpPage");

    this.getCompany();
  },

  data() {
    return {
      company: {},
    };
  },

  methods: {
    getCompany() {
      this.$http
        .show("app/recommendation/accept", this.$route.params.token)
        .then((response) => {
          this.company = response.company;
        })
        .catch((error) => {});
    },
  },
};
</script>

 